<template>
  <div class="home">
    <div>
      <div class="back">
          <div class="centerbox">{{GLOBAL}}</div>
          <div class="priceInfo">
                <div class="infoTop "> 
                     <div><van-icon name="tosend" />  {{ timecenter }}</div>
                     <div class="open" :class="opentrade ? 'greenfont' : 'redfont'">
                       {{ opentrade == true ? "开盘" : "停盘" }}
                     </div>
                </div>
                <div class="infoBtm">
                    黄金，铂金，钯金，白银，钻石购销
                </div>
          </div>
             
          </div>
    </div>
    <div v-if="havedata">
      <ul class="flex ulflex topul">
        <li class="firstbox">&nbsp;</li>
        <li style="flex: 3">商品</li>
        <li>回购</li>
        <li>销售</li>
        <li>高低</li>
      </ul>
      <!-- 黄金 -->
      <div class="productitems">
        <div
          v-for="(items, index) in arrcenter.spotPrice"
          :key="items.productData['name']"
          class="ulcenter"
        >
          <div
            :class="arrcenter.huangjin.length % 2 == 0 ? 'oddli' : ''"
            v-if="index == 1"
            class="firstbox"
          >
            {{GLOBAL}}行情
          </div>
          <ul
            class="flex ulflex productlist"
            :class="
              index == arrcenter.huangjin.length - 1
                ? 'lastul'
                : index == 0
                ? ''
                : ''
            "
          >
            <!-- 加了注释（以下的位置都一样） -->

             <li class="firstbox">
             
             </li> 
            <li class="flex_product">{{ items.engname }}</li>
            <template v-if="sendmsg">
              <li
                :class="
                  // new_tuisong_val_before['huangjin'].prices[items['engname']][
                  //   'huigou'
                  // ] <= items.productData['huigou']
                  //   ? 'redfont'
                  //   : 'greenfont'
                  items.productData['huigouColor']
                "
              >
              
                <template v-if="items.productData['hgDisplay'] == 'SHOW'">  
              {{ items.productData["huigou"] | changeTwoDecimal_x }}
                 
                </template>
                <template v-else> - </template>
              </li>
              <li
                :class="
                  // new_tuisong_val_before['huangjin'].prices[items['engname']][
                  //   'xiaoshou'
                  // ] <= items.productData['xiaoshou']
                  //   ? 'redfont'
                  //   : 'greenfont'
                  items.productData['huigouColor']
                "
              >
                <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                  {{ items.engname == '黄金'? items.productData["xiaoshou"] :'---'}}
                </template>
                <template v-else> - </template>
              </li>
              <li class="lastli">
                <div
                  :class="
                    // new_tuisong_val_before['huangjin'].prices[items['engname']][
                    //   'high'
                    // ] <= items.productData['high']
                    //   ? 'redfont'
                    //   : 'greenfont'
                    items.productData['huigouColor']
                  "
                >
                  {{ items.productData["high"] | changeTwoDecimal_x }}
                </div>
                <div
                  :class="
                    // new_tuisong_val_before['huangjin'].prices[items['engname']][
                    //   'low'
                    // ] <= items.productData['low']
                    //   ? 'redfont'
                    //   : 'greenfont'
                    items.productData['huigouColor']
                  "
                >
                  {{ items.productData["low"] | changeTwoDecimal_x }}
                </div>
              </li>
            </template>
            <template v-else>
              <li class="redfont">
                <template v-if="items.productData['hgDisplay'] == 'SHOW'">
                  <div>
                    {{ items.productData["huigou"] | changeTwoDecimal_x }}
                  </div>
                </template>
                <template v-else> - </template>
                <!-- {{items.productData['huigou'] | changeTwoDecimal_f}} -->
              </li>
              <li class="redfont">
                <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                  {{ items.productData["xiaoshou"] | changeTwoDecimal_x }}
                </template>
                <template v-else> - </template>
                <!-- {{items.productData['xiaoshou'] | changeTwoDecimal_f}} -->
              </li>
              <li class="lastli">
                <div>{{ items.productData["high"] | changeTwoDecimal_x }}</div>
                <div>{{ items.productData["low"] | changeTwoDecimal_x }}</div>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <!-- 分割线 -->
      <div class="hrxian"></div>
      <!-- 分割线 -->

      <!-- 上金所 -->
      <div class="productitems">
        <div
          v-for="(items, index) in arrcenter.shangjinsuo"
          :key="items.productData['name']"
          class="ulcenter"
        >
          <div
            :class="arrcenter.huangjin.length % 2 == 0 ? 'oddli' : ''"
            v-if="index == 1"
            class="firstbox"
          >
            上海黄金交易所
          </div>
          <ul
            class="flex ulflex productlist"
            :class="
              index == arrcenter.shangjinsuo.length - 1
                ? 'lastul'
                : index == 0
                ? 'firstul'
                : ''
            "
            v-if="items.productData['display'] == 'SHOW'"
          >
            <li class="firstbox">
              <!-- {{index+1 == (arrcenter.shangjinsuo.length/2).toFixed()?'上金所':''}} -->
            </li>
            <li class="flex_product">{{ items.productData["name"] }}</li>
            <template v-if="sendmsg">
              <li
                :class="
                  new_tuisong_val_before['sge'].prices[items['engname']][
                    'huigou'
                  ] <= items.productData['huigou']
                    ? 'redfont'
                    : 'greenfont'
                "
              >
                <!-- {{items.productData['huigou'] | changeTwoDecimal_f}} -->
                <template v-if="items.productData['hgDisplay'] == 'SHOW'">
                  <div v-if="items.productData['name'] == '白银(T+D)'">
                    {{ items.productData["huigou"] }}
                  </div>
                  <div v-else>
                    {{ items.productData["huigou"] | changeTwoDecimal_f }}
                  </div>
                  <!-- <template v-if="items.productData['name'] == '白银(T+D)'">
                </template>
                <template v-else> -->
                  <!-- {{items.productData['huigou'] | changeTwoDecimal_f}}       -->
                  <!-- </template> -->
                </template>
                <template v-else> - </template>
              </li>
              <li
                :class="
                  new_tuisong_val_before['sge'].prices[items['engname']][
                    'xiaoshou'
                  ] <= items.productData['xiaoshou']
                    ? 'redfont'
                    : 'greenfont'
                "
              >
                <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                  <!-- {{items.productData['xiaoshou'] | changeTwoDecimal_f}}       -->
                  <div v-if="items.productData['name'] == '白银(T+D)'">
                    {{ items.productData["xiaoshou"] }}
                  </div>
                  <div v-else>
                    {{ items.productData["xiaoshou"] | changeTwoDecimal_f }}
                  </div>
                </template>
                <template v-else> - </template>
              </li>
              <li class="lastli">
                <template v-if="items.productData['name'] == '白银(T+D)'">
                  <div
                    :class="
                      new_tuisong_val_before['sge'].prices[items['engname']][
                        'high'
                      ] <= items.productData['high']
                        ? 'redfont'
                        : 'greenfont'
                    "
                  >
                    {{ items.productData["high"] }}
                  </div>
                  <div
                    :class="
                      new_tuisong_val_before['sge'].prices[items['engname']][
                        'low'
                      ] <= items.productData['low']
                        ? 'redfont'
                        : 'greenfont'
                    "
                  >
                    {{ items.productData["low"] }}
                  </div>
                </template>
                <template v-else>
                  <div
                    :class="
                      new_tuisong_val_before['sge'].prices[items['engname']][
                        'high'
                      ] <= items.productData['high']
                        ? 'redfont'
                        : 'greenfont'
                    "
                  >
                    {{ items.productData["high"] | changeTwoDecimal_f }}
                  </div>
                  <div
                    :class="
                      new_tuisong_val_before['sge'].prices[items['engname']][
                        'low'
                      ] <= items.productData['low']
                        ? 'redfont'
                        : 'greenfont'
                    "
                  >
                    {{ items.productData["low"] | changeTwoDecimal_f }}
                  </div>
                </template>
              </li>
            </template>
            <template v-else>
              <li class="redfont">
                <template v-if="items.productData['hgDisplay'] == 'SHOW'">
                  <!-- {{items.productData['huigou'] | changeTwoDecimal_f}}     -->
                  <div v-if="items.productData['name'] == '白银(T+D)'">
                    {{ items.productData["huigou"] }}
                  </div>
                  <div v-else>
                    {{ items.productData["huigou"] | changeTwoDecimal_f }}
                  </div>
                </template>
                <template v-else> - </template>
                <!-- {{items.productData['huigou'] | changeTwoDecimal_f}} -->
              </li>
              <li class="redfont">
                <!-- {{items.productData['xiaoshou'] | changeTwoDecimal_f}} -->
                <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                  <!-- {{items.productData['xiaoshou'] | changeTwoDecimal_f}}       -->
                  <div v-if="items.productData['name'] == '白银(T+D)'">
                    {{ items.productData["xiaoshou"] }}
                  </div>
                  <div v-else>
                    {{ items.productData["xiaoshou"] | changeTwoDecimal_f }}
                  </div>
                </template>
                <template v-else> - </template>
              </li>
              <li class="lastli">
                <template v-if="items.productData['name'] == '白银(T+D)'">
                  <div>{{ items.productData["high"] }}</div>
                  <div>{{ items.productData["low"] }}</div>
                </template>
                <template v-else>
                  <div>
                    {{ items.productData["high"] | changeTwoDecimal_f }}
                  </div>
                  <div>{{ items.productData["low"] | changeTwoDecimal_f }}</div>
                </template>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <!-- 分割线 -->
      <div class="hrxian"></div>
      <!-- 分割线 -->

      <!-- 国际现货 -->
      <div class="productitems">
        <div
          v-for="(items, index) in arrcenter.guojixianhuo"
          :key="items.productData['name']"
          class="ulcenter"
        >
         <div
            :class="arrcenter.huangjin.length % 2 == 0 ? 'oddli' : ''"
            v-if="index == 1"
            class="firstbox"
          >
            国际市场
          </div>
          <ul
            class="flex ulflex productlist"
            :class="
              index == arrcenter.guojixianhuo.length - 1
                ? 'lastul'
                : index == 0
                ? 'firstul'
                : ''
            "
            v-if="items.productData['display'] == 'SHOW'"
          >
            <li class="firstbox">
            </li>
            <li class="flex_product">{{ items.productData["name"] }}</li>
            <template v-if="sendmsg">
              <li
                :class="
                  new_tuisong_val_before['international'].prices[
                    items['engname']
                  ]['huigou'] <= items.productData['huigou']
                    ? 'redfont'
                    : 'greenfont'
                "
              >
                <template
                  v-if="
                    items.productData['name'] == '伦敦金' ||
                      items.productData['name'] == '伦敦钯' ||
                      items.productData['name'] == '伦敦铂'
                  "
                >
                  {{ items.productData["huigou"] | changeTwoDecimal_f }}
                </template>
                <template
                  v-else-if="
                    items.productData['name'] == '伦敦银' ||
                      items.productData['name'] == '美元指数'
                  "
                >
                  {{ items.productData["huigou"] | changeTwoDecimal_fthree }}
                </template>
                <template v-else>
                  {{ items.productData["huigou"] }}
                </template>
              </li>
              <li
                :class="
                  new_tuisong_val_before['international'].prices[
                    items['engname']
                  ]['xiaoshou'] <= items.productData['xiaoshou']
                    ? 'redfont'
                    : 'greenfont'
                "
              >
                <template
                  v-if="
                    items.productData['name'] == '伦敦金' ||
                      items.productData['name'] == '伦敦钯' ||
                      items.productData['name'] == '伦敦铂'
                  "
                >
                  {{ items.productData["xiaoshou"] | changeTwoDecimal_f }}
                </template>
                <template
                  v-else-if="
                    items.productData['name'] == '伦敦银' ||
                      items.productData['name'] == '美元指数'
                  "
                >
                  {{ items.productData["xiaoshou"] | changeTwoDecimal_f }}
                </template>
                <template v-else>
                  {{ items.productData["xiaoshou"] }}
                </template>
              </li>
              <li class="lastli">
                <div
                  :class="
                    new_tuisong_val_before['international'].prices[
                      items['engname']
                    ]['high'] <= items.productData['high']
                      ? 'redfont'
                      : 'greenfont'
                  "
                >
                  <template
                    v-if="
                      items.productData['name'] == '伦敦金' ||
                        items.productData['name'] == '伦敦钯' ||
                        items.productData['name'] == '伦敦铂'
                    "
                  >
                    {{ items.productData["high"] | changeTwoDecimal_f }}
                  </template>
                  <template
                    v-else-if="
                      items.productData['name'] == '伦敦银' ||
                        items.productData['name'] == '美元指数'
                    "
                  >
                    {{ items.productData["high"] | changeTwoDecimal_f }}
                  </template>
                  <template v-else>
                    {{ items.productData["high"] }}
                  </template>
                </div>
                <div
                  :class="
                    new_tuisong_val_before['international'].prices[
                      items['engname']
                    ]['low'] <= items.productData['low']
                      ? 'redfont'
                      : 'greenfont'
                  "
                >
                  <template
                    v-if="
                      items.productData['name'] == '伦敦金' ||
                        items.productData['name'] == '伦敦钯' ||
                        items.productData['name'] == '伦敦铂'
                    "
                  >
                    {{ items.productData["low"] | changeTwoDecimal_f }}
                  </template>
                  <template
                    v-else-if="
                      items.productData['name'] == '伦敦银' ||
                        items.productData['name'] == '美元指数'
                    "
                  >
                    {{ items.productData["low"] | changeTwoDecimal_f }}
                  </template>
                  <template v-else>
                    {{ items.productData["low"] }}
                  </template>
                </div>
              </li>
            </template>

            <template v-else>
              <li class="redfont">
                <template
                  v-if="
                    items.productData['name'] == '伦敦金' ||
                      items.productData['name'] == '伦敦钯' ||
                      items.productData['name'] == '伦敦铂'
                  "
                >
                  {{ items.productData["huigou"] | changeTwoDecimal_f }}
                </template>
                <template
                  v-else-if="
                    items.productData['name'] == '伦敦银' ||
                      items.productData['name'] == '美元指数'
                  "
                >
                  {{ items.productData["huigou"] | changeTwoDecimal_f }}
                </template>
                <template v-else>
                  {{ items.productData["huigou"] }}
                </template>
                <!-- {{items.productData['huigou']}} -->
              </li>
              <li class="redfont">
                {{ items.productData["xiaoshou"] }}
              </li>
              <li class="lastli">
                <div>{{ items.productData["high"] }}</div>
                <div>{{ items.productData["low"] }}</div>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <footer class="foot">
            <p>声明:杜绝来路不明的黄金等贵金属、杜绝销售者参与洗钱活动、黄金买卖以实时行情价清账(贷款两清)、定价交易的在七天内结清(须交定金)、定金不可低于金价波动差价。以上行情仅供参考！</p>
            <p class="textDecoration">公司地址: &nbsp;&nbsp; 北京市东城区天雅珠宝城一楼</p>

      </footer>
      <!-- 时间 是否开盘中 -->
      <!-- <div class="flex-between fixedbottom">
        <div>{{ timecenter }}</div>
        <div class="open" :class="opentrade ? 'greenfont' : 'redfont'">
          {{ opentrade == true ? "开盘中" : "已停盘" }}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stomp from "stompjs";
export default {
  data() {
    return {
      // 下拉刷新
      isLoading:false,
      year: "",
      mouth: Number,
      day: Number,
      sendmsg: false,
      onedata: [],
      // 旧值判断
      isfirstval: false,
      // 克隆后获取之前的值做比较
      lastarr: [],
      // 深度克隆后的赋值
      updataarr: [],
      firstload: true,
      wsPrice: null,
      prePrice: {},
      isload: true,
      arrcenter: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: [],
        // 现货价格
        spotPrice:[]
      },
      newarrlist: [],
      // 是否开盘中
      opentrade: false,
      havedata: false,
      timecenter: "",
      client: {},
      productall: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: []
      },
      new_tuisong_val_last: {},
      new_tuisong_val_before: {},

      Compareval: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: []
      }
    };
  },
  mounted() {
    const that = this;
    this.connect();
    setInterval(() => {
      const t = that.onedata["time"];
      const s = new Date().getTime()
      let date = new Date(Number(t) * 1000);
      if (isNaN(t)) {
        date = new Date();
      }

      this.timebox(date);
    },500);
  },
  computed: {},
  watch: {
    onedata: function(newN, oldN) {
      const than = this;
      // console.log(newN);
      Object.entries(newN).forEach(([typeKey, type]) => {
        if (["time", "trade", "id"].includes(typeKey)) {
          return;
        }
        Object.entries(type.prices).forEach(([goodsKey, goods]) => {
          //  debugger
          let huigouColor = "redfont";
          if (oldN && !Array.isArray(oldN)) {
            const oldGoods = oldN[typeKey].prices[goodsKey];
            const oldHuigou = oldGoods["huigou"];
            const newHuigou = goods["huigou"];
            const oldHuigouColor = oldGoods["huigouColor"];
            // console.log(newHuigou, oldHuigou);
            // console.log(oldHuigouColor);
            // debugger
            huigouColor =
              newHuigou < oldHuigou
                ? "greenfont"
                : newHuigou == oldHuigou
                ? oldHuigouColor
                : "redfont";
          }
          goods["huigouColor"] = huigouColor;
        });
      });
      if (oldN instanceof Array) {
      } else {
        this.new_tuisong_val_before = JSON.parse(JSON.stringify(oldN));
        this.new_tuisong_val_last = JSON.parse(JSON.stringify(newN));
        this.sendmsg = true;
        this.newlistsort(this.new_tuisong_val_last);
      }
    }
  },
  filters: {
    changeTwoDecimal_f(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 2) {
        s_x += "0";
      }
        return s_x;
      
    },    
    changeTwoDecimal_x(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 2) {
        s_x += "0";
      }

         if (s_x.length >= 7) {
        // console.log('sx',s_x,'x',Number(s_x).toFixed(3));

        return Number(s_x).toFixed(3);
      }

        return s_x;
   
    },
    changeTwoDecimal_fthree(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 3) {
        s_x += "0";
      }
      if (s_x.length >= 7) {
        return Number(s_x).toFixed(3);
      } 
        return s_x;
    }
  },
  methods: {
    onRefresh(){

        this.connect()
        this.isLoading = false;
    },
    banklist(){
      this.$router.push('/bank_list')
    },
    // Buygold(){
    //   this.$router.push('/Buy_gold')
    // },
    quoted(){
      this.$router.push('/quoted')
    },
    newlistsort(data) {
      const than = this;
      than.arrcenter.huangjin = [];
      than.arrcenter.bojin = [];
      than.arrcenter.bajin = [];
      than.arrcenter.baiyin = [];
      than.arrcenter.guojixianhuo = [];
      than.arrcenter.shangjinsuo = [];
      than.arrcenter.spotPrice =[]
      than.productall.huangjin = [];
      than.productall.bojin = [];
      than.productall.bajin = [];
      than.productall.baiyin = [];
      than.productall.shangjinsuo = [];
      const resbody = data;
      for (const i in resbody) {
        if (resbody[i].name === "黄金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.huangjin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });

            }
          }

          this.arrcenter.huangjin.sort(this.compare("sort"));
          // this.productall.huangjin = resbody[i].prices
        }
        if (resbody[i].name === "铂金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.bojin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.bojin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "钯金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.bajin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.bajin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "白银") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.baiyin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b],
                time: resbody.time
              });
            }
          }
          this.arrcenter.baiyin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "上金所") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.shangjinsuo.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          
          this.arrcenter.shangjinsuo.sort(this.compare("sort"));
        }
        if (resbody[i].name === "国际现货") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.guojixianhuo.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.guojixianhuo.sort(this.compare("sort"));
        }
      }


                let res = 0
            this.arrcenter.huangjin.forEach((item,i)=>{
              if (item.engname  == "huangjin9999") {
                  res = item.productData.huigou
                    this.arrcenter.spotPrice.push({
                      engname: '黄金',
                      productData: item.productData
                  })
              }
            })            
                let res1 = 0
            this.arrcenter.baiyin.forEach((item,i)=>{
              if (item.engname  == "baiyin9999") {
                  res1 = item.productData.huigou
                    this.arrcenter.spotPrice.push({
                      engname: '白银',
                      productData: item.productData
                  })
              }
            })    
                let res2 = 0            
            this.arrcenter.bojin.forEach((item,i)=>{
              if (item.engname == "bojin9996") {
                  res2 = item.productData.huigou
                    this.arrcenter.spotPrice.push({
                      engname: '铂金',
                      productData: item.productData
                  })
              }
            })
                let res3 = 0
            this.arrcenter.bajin.forEach((item,i)=>{
              if (item.engname == "bajin9996") {
                  res3 = item.productData.huigou

                    this.arrcenter.spotPrice.push({
                      engname: '钯金',
                      productData: item.productData
                  })
              }
            })            
    },
    // 排序
    compare(property) {
      return function(a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    },
    sortNumber(a) {
      return a;
    },
    timebox(date) {
      // const year = new Date().getFullYear();
      // const month = new Date().getMonth() + 1;
      // const day = new Date().getDate();
      // const hour = new Date().getHours();
      // const Min = new Date().getMinutes();
      // const getSeconds = new Date().getSeconds();

      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const Min = date.getMinutes();
      const getSeconds = date.getSeconds();
      this.timecenter =
        year +
        "-" +
        (month <= 9 ? "0" + month : month) +
        "-" +
        (day <= 9 ? "0" + day : day) +
        " " +
        (hour <= 9 ? "0" + hour : hour) +
        ":" +
        (Min <= 9 ? "0" + Min : Min) +
        ":" +
        (getSeconds <= 9 ? "0" + getSeconds : getSeconds);

      if (hour == "16" && Min == "20" && getSeconds == "40") {
        this.$eventBus.$emit("onclickLists");
      }
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    onConnected() {
      // console.log(this.client)
      const topic = "/price/all";
      // let topic = '/location'
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    connect() {
      console.log("process.env",process.env)
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onFailed(frame) {
      console.log("错误: " + frame);
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      if (!this.client.connected) {
        this.opentrade = false;
        setTimeout(() => {
          this.connect();
        }, 1000);
      }
    },
    deepObjectMerge(FirstOBJ_, SecondOBJ) {
      // console.log("函数内的数据")
      const FirstOBJ = FirstOBJ_ || this.wsPrice;
      for (const key in SecondOBJ) {
        FirstOBJ[key] =
          FirstOBJ[key] && FirstOBJ[key].toString() === "[object Object]"
            ? this.deepObjectMerge(FirstOBJ[key], SecondOBJ[key])
            : (FirstOBJ[key] = SecondOBJ[key]);
      }
      return FirstOBJ;
    },
    deepMerge(...objs) {
      const than = this;
      const result = Object.create(null);
      objs.forEach(obj => {

        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val);
              } else {
                result[key] = than.deepMerge(val);
              }
            } else {
              //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val];
              } else {
                result[key] = val;
              }
            }
          });
        }
      });
      return result;
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString;
      return toString.call(val) === "[object Object]";
    },
    extend() {
      const than = this;
      const extended = {};
      let deep = false;
      let i = 0;
      // 判断是否为深拷贝
      if (Object.prototype.toString.call(arguments[0]) === "[object Boolean]") {
        deep = arguments[0];
        i++; //如果为深拷贝则初始的i为1或者为0
      }

      // 将对象属性合并到已存在的对象中
      const merge = function(obj) {
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            // 如果属性为对象并且需要深拷贝时则使用函数递归、反之则将当前的属性替换现有的属性
            if (
              deep &&
              Object.prototype.toString.call(obj[prop]) === "[object Object]"
            ) {
              extended[prop] = than.extend(extended[prop], obj[prop]);
            } else {
              extended[prop] = obj[prop];
            }
          }
        }
      };
      // 遍历所有对象属性
      for (; i < arguments.length; i++) {
        merge(arguments[i]);
      }
      return extended;
    },
    responseCallback(frame) {
      const than = this;
      // than.onedata = []
      // console.log(eval("[" + this.unzip(frame.body) + "]"));
      const res_data = eval("[" + this.unzip(frame.body) + "]")[0];
      const parse_res = JSON.parse(JSON.stringify(res_data));
      localStorage.setItem('priceID',parse_res.time)
      if (!this.firstload) {
        than.onedata = than.deepMerge(than.wsPrice, parse_res);
        // --------------------
        than.wsPrice = than.onedata;
        // --------------------
      } else {
        than.firstload = false;
        than.updataarr = parse_res;
        than.wsPrice = parse_res;
      }
      console.debug(parse_res);
      //  是否开盘中
      this.opentrade = parse_res.trade;
      // 保存本地
      localStorage.setItem('opentrade',parse_res.trade)
      if (this.isload) {
        let resbody = "";
        if (JSON.stringify(than.new_tuisong_val_last) == "{}") {
          resbody = than.updataarr;
          this.newlistsort(resbody);
        } else {
        }
      }
      this.isload = true;
      this.havedata = true;
    },
    addRouterMap() {
      this.$router.push("/aboutUs");
    }
  },
  destroyed() {
    clearTimeout(this.setInterval);
    this.client.disconnect(function() {
      // 断开连接
      // console.log("See you next time!");
    });
  },
  components: {}
};
</script>
<style lang="scss" scoped="true">
::v-deep{
.van-icon{
    top: 2px;
    font-size: 28px;
}
}
.redfont {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d5182d !important;
}
.greenfont {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #12913e !important;
}
.loadingbox {
  margin-top: 250px;
}
.home {
  .borderbox {
    border-bottom: 1px solid #e6e6e6;
  }
  .righbox {
    position: absolute;
    right: 20px;
    top: 12px;
    font-size: 32px;
    top: 35px;
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .centerbox {
    display: block;
    color: #fff;
    text-align: center;
    font-size: 36px;
    line-height: 120px;

  }
  .back {
    background: #882a2a;
    position: relative;
    // z-index: 5;
    height: 120px;
    z-index: 1;
    font-size: 28px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    color: #fff;
  }
  .whitefont {
    color: #fff !important;
  }
  .fixedbottom {
    border-top: 1px solid #ccc;
    position: fixed;
    bottom: 100px;
    height: 80px;
    left: 0;
    background: #fff;
    line-height: 80px;
    padding: 0 30px;
    width: 100%;
    color: #333232;
    font-size: 28px;
    z-index: 8;

  }
  .hrxian {
    height: 20px;
  }
  .firstul {
    border-top: 1px solid #cccccc;
  }
  .topul {
    text-align: center;
    font-size: 28px;
    line-height: 50px;
    color: #ff9d00;
    li{
            border-left: 1px solid #ccc;
    }
  }
  li {
    flex: 2;
  }
  .firstbox {
    position: relative;
    color: #fba215;
    flex: 1;
    background: #fff;
    word-wrap: break-word;
    writing-mode: vertical-rl;
    writing-mode: tb-rl;
    font-size: 28px;
    display: flex;
    padding: 0 !important;
    -webkit-display: flex;
    flex-direction: column;
    /* align-content: center; */
    /* vertical-align: middle; */
    border-left: none !important;
    border-bottom: none !important;
    justify-content: center;
  }
  .flex_product {
    flex: 3;
    font-weight: 400;
    // width: 210px;
    border-left: 1px solid #cccccc;
    line-height: 70px;
  }
  .productitems {
    position: relative;
  }
  .productitems .ulcenter {
    
    &:nth-child(odd) {
      background: #eaeaea;
    }
  }
  .productlist {
    li {
      border-bottom: 1px solid #cccccc;
      border-left: 1px solid #cccccc;
      text-align: center;
      padding: 4px 0;
      font-size: 28px;
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        color: red;
      }
    }
    .lastli {
        font-weight: 600;
    }
  }
  .lastul {
    border-bottom: 1px solid #cccccc;
    li {
      border-bottom: none;
    }
  }
}
.lastli {
  display: grid !important;
  // padding: 30px 0 0 0!important;
}
.oddli {
  z-index: 3 !important;
  position: absolute !important;
  left: 3% !important;
  top: 50% !important;
  letter-spacing :6px;
  height: 270px;
  transform: translateY(-50%) !important;
}
.foot{
    background-color:#792f2c ;
    color: #fff;
    font-size: 28px;
    padding: 30px 20px 50px;
    margin-top: 20px;
    font-weight: 400;
    .textDecoration{
        // text-decoration:underline;
        margin: 25px 0;
    }
}
.open {
    font-size: 22px;
    width: 60px;
    padding: 4px 0;
    background-color: #fff;
    margin-left: 20px;
    border-radius: 4px;

}
.priceInfo{
    padding: 20px 0;
}
.infoTop{
    display: flex;
    margin-bottom: 6px;
}
.productitems{
    font-weight: 600;
}
</style>
