var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", [
      _c("div", { staticClass: "back" }, [
        _c("div", { staticClass: "centerbox" }, [_vm._v(_vm._s(_vm.GLOBAL))]),
        _c("div", { staticClass: "priceInfo" }, [
          _c("div", { staticClass: "infoTop " }, [
            _c(
              "div",
              [
                _c("van-icon", { attrs: { name: "tosend" } }),
                _vm._v(" " + _vm._s(_vm.timecenter))
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "open",
                class: _vm.opentrade ? "greenfont" : "redfont"
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.opentrade == true ? "开盘" : "停盘") + " "
                )
              ]
            )
          ]),
          _c("div", { staticClass: "infoBtm" }, [
            _vm._v(" 黄金，铂金，钯金，白银，钻石购销 ")
          ])
        ])
      ])
    ]),
    _vm.havedata
      ? _c("div", [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "productitems" },
            _vm._l(_vm.arrcenter.spotPrice, function(items, index) {
              return _c(
                "div",
                { key: items.productData["name"], staticClass: "ulcenter" },
                [
                  index == 1
                    ? _c(
                        "div",
                        {
                          staticClass: "firstbox",
                          class:
                            _vm.arrcenter.huangjin.length % 2 == 0
                              ? "oddli"
                              : ""
                        },
                        [_vm._v(" " + _vm._s(_vm.GLOBAL) + "行情 ")]
                      )
                    : _vm._e(),
                  _c(
                    "ul",
                    {
                      staticClass: "flex ulflex productlist",
                      class:
                        index == _vm.arrcenter.huangjin.length - 1
                          ? "lastul"
                          : index == 0
                          ? ""
                          : ""
                    },
                    [
                      _c("li", { staticClass: "firstbox" }),
                      _c("li", { staticClass: "flex_product" }, [
                        _vm._v(_vm._s(items.engname))
                      ]),
                      _vm.sendmsg
                        ? [
                            _c(
                              "li",
                              {
                                // new_tuisong_val_before['huangjin'].prices[items['engname']][
                                class:
                                  //   'huigou'
                                  // ] <= items.productData['huigou']
                                  //   ? 'redfont'
                                  //   : 'greenfont'
                                  items.productData["huigouColor"]
                              },
                              [
                                items.productData["hgDisplay"] == "SHOW"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("changeTwoDecimal_x")(
                                              items.productData["huigou"]
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  : [_vm._v(" - ")]
                              ],
                              2
                            ),
                            _c(
                              "li",
                              {
                                // new_tuisong_val_before['huangjin'].prices[items['engname']][
                                class:
                                  //   'xiaoshou'
                                  // ] <= items.productData['xiaoshou']
                                  //   ? 'redfont'
                                  //   : 'greenfont'
                                  items.productData["huigouColor"]
                              },
                              [
                                items.productData["xsDisplay"] == "SHOW"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            items.engname == "黄金"
                                              ? items.productData["xiaoshou"]
                                              : "---"
                                          ) +
                                          " "
                                      )
                                    ]
                                  : [_vm._v(" - ")]
                              ],
                              2
                            ),
                            _c("li", { staticClass: "lastli" }, [
                              _c(
                                "div",
                                {
                                  // new_tuisong_val_before['huangjin'].prices[items['engname']][
                                  class:
                                    //   'high'
                                    // ] <= items.productData['high']
                                    //   ? 'redfont'
                                    //   : 'greenfont'
                                    items.productData["huigouColor"]
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("changeTwoDecimal_x")(
                                          items.productData["high"]
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  // new_tuisong_val_before['huangjin'].prices[items['engname']][
                                  class:
                                    //   'low'
                                    // ] <= items.productData['low']
                                    //   ? 'redfont'
                                    //   : 'greenfont'
                                    items.productData["huigouColor"]
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("changeTwoDecimal_x")(
                                          items.productData["low"]
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        : [
                            _c(
                              "li",
                              { staticClass: "redfont" },
                              [
                                items.productData["hgDisplay"] == "SHOW"
                                  ? [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("changeTwoDecimal_x")(
                                                items.productData["huigou"]
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  : [_vm._v(" - ")]
                              ],
                              2
                            ),
                            _c(
                              "li",
                              { staticClass: "redfont" },
                              [
                                items.productData["xsDisplay"] == "SHOW"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("changeTwoDecimal_x")(
                                              items.productData["xiaoshou"]
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  : [_vm._v(" - ")]
                              ],
                              2
                            ),
                            _c("li", { staticClass: "lastli" }, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("changeTwoDecimal_x")(
                                      items.productData["high"]
                                    )
                                  )
                                )
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("changeTwoDecimal_x")(
                                      items.productData["low"]
                                    )
                                  )
                                )
                              ])
                            ])
                          ]
                    ],
                    2
                  )
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "hrxian" }),
          _c(
            "div",
            { staticClass: "productitems" },
            _vm._l(_vm.arrcenter.shangjinsuo, function(items, index) {
              return _c(
                "div",
                { key: items.productData["name"], staticClass: "ulcenter" },
                [
                  index == 1
                    ? _c(
                        "div",
                        {
                          staticClass: "firstbox",
                          class:
                            _vm.arrcenter.huangjin.length % 2 == 0
                              ? "oddli"
                              : ""
                        },
                        [_vm._v(" 上海黄金交易所 ")]
                      )
                    : _vm._e(),
                  items.productData["display"] == "SHOW"
                    ? _c(
                        "ul",
                        {
                          staticClass: "flex ulflex productlist",
                          class:
                            index == _vm.arrcenter.shangjinsuo.length - 1
                              ? "lastul"
                              : index == 0
                              ? "firstul"
                              : ""
                        },
                        [
                          _c("li", { staticClass: "firstbox" }),
                          _c("li", { staticClass: "flex_product" }, [
                            _vm._v(_vm._s(items.productData["name"]))
                          ]),
                          _vm.sendmsg
                            ? [
                                _c(
                                  "li",
                                  {
                                    class:
                                      _vm.new_tuisong_val_before["sge"].prices[
                                        items["engname"]
                                      ]["huigou"] <= items.productData["huigou"]
                                        ? "redfont"
                                        : "greenfont"
                                  },
                                  [
                                    items.productData["hgDisplay"] == "SHOW"
                                      ? [
                                          items.productData["name"] ==
                                          "白银(T+D)"
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      items.productData[
                                                        "huigou"
                                                      ]
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "changeTwoDecimal_f"
                                                      )(
                                                        items.productData[
                                                          "huigou"
                                                        ]
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ]
                                      : [_vm._v(" - ")]
                                  ],
                                  2
                                ),
                                _c(
                                  "li",
                                  {
                                    class:
                                      _vm.new_tuisong_val_before["sge"].prices[
                                        items["engname"]
                                      ]["xiaoshou"] <=
                                      items.productData["xiaoshou"]
                                        ? "redfont"
                                        : "greenfont"
                                  },
                                  [
                                    items.productData["xsDisplay"] == "SHOW"
                                      ? [
                                          items.productData["name"] ==
                                          "白银(T+D)"
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      items.productData[
                                                        "xiaoshou"
                                                      ]
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "changeTwoDecimal_f"
                                                      )(
                                                        items.productData[
                                                          "xiaoshou"
                                                        ]
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ]
                                      : [_vm._v(" - ")]
                                  ],
                                  2
                                ),
                                _c(
                                  "li",
                                  { staticClass: "lastli" },
                                  [
                                    items.productData["name"] == "白银(T+D)"
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              class:
                                                _vm.new_tuisong_val_before[
                                                  "sge"
                                                ].prices[items["engname"]][
                                                  "high"
                                                ] <= items.productData["high"]
                                                  ? "redfont"
                                                  : "greenfont"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    items.productData["high"]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              class:
                                                _vm.new_tuisong_val_before[
                                                  "sge"
                                                ].prices[items["engname"]][
                                                  "low"
                                                ] <= items.productData["low"]
                                                  ? "redfont"
                                                  : "greenfont"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    items.productData["low"]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      : [
                                          _c(
                                            "div",
                                            {
                                              class:
                                                _vm.new_tuisong_val_before[
                                                  "sge"
                                                ].prices[items["engname"]][
                                                  "high"
                                                ] <= items.productData["high"]
                                                  ? "redfont"
                                                  : "greenfont"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "changeTwoDecimal_f"
                                                    )(items.productData["high"])
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              class:
                                                _vm.new_tuisong_val_before[
                                                  "sge"
                                                ].prices[items["engname"]][
                                                  "low"
                                                ] <= items.productData["low"]
                                                  ? "redfont"
                                                  : "greenfont"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "changeTwoDecimal_f"
                                                    )(items.productData["low"])
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ]
                            : [
                                _c(
                                  "li",
                                  { staticClass: "redfont" },
                                  [
                                    items.productData["hgDisplay"] == "SHOW"
                                      ? [
                                          items.productData["name"] ==
                                          "白银(T+D)"
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      items.productData[
                                                        "huigou"
                                                      ]
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "changeTwoDecimal_f"
                                                      )(
                                                        items.productData[
                                                          "huigou"
                                                        ]
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ]
                                      : [_vm._v(" - ")]
                                  ],
                                  2
                                ),
                                _c(
                                  "li",
                                  { staticClass: "redfont" },
                                  [
                                    items.productData["xsDisplay"] == "SHOW"
                                      ? [
                                          items.productData["name"] ==
                                          "白银(T+D)"
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      items.productData[
                                                        "xiaoshou"
                                                      ]
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "changeTwoDecimal_f"
                                                      )(
                                                        items.productData[
                                                          "xiaoshou"
                                                        ]
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ]
                                      : [_vm._v(" - ")]
                                  ],
                                  2
                                ),
                                _c(
                                  "li",
                                  { staticClass: "lastli" },
                                  [
                                    items.productData["name"] == "白银(T+D)"
                                      ? [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(items.productData["high"])
                                            )
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(items.productData["low"])
                                            )
                                          ])
                                        ]
                                      : [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("changeTwoDecimal_f")(
                                                    items.productData["high"]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("changeTwoDecimal_f")(
                                                  items.productData["low"]
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                  ],
                                  2
                                )
                              ]
                        ],
                        2
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "hrxian" }),
          _c(
            "div",
            { staticClass: "productitems" },
            _vm._l(_vm.arrcenter.guojixianhuo, function(items, index) {
              return _c(
                "div",
                { key: items.productData["name"], staticClass: "ulcenter" },
                [
                  index == 1
                    ? _c(
                        "div",
                        {
                          staticClass: "firstbox",
                          class:
                            _vm.arrcenter.huangjin.length % 2 == 0
                              ? "oddli"
                              : ""
                        },
                        [_vm._v(" 国际市场 ")]
                      )
                    : _vm._e(),
                  items.productData["display"] == "SHOW"
                    ? _c(
                        "ul",
                        {
                          staticClass: "flex ulflex productlist",
                          class:
                            index == _vm.arrcenter.guojixianhuo.length - 1
                              ? "lastul"
                              : index == 0
                              ? "firstul"
                              : ""
                        },
                        [
                          _c("li", { staticClass: "firstbox" }),
                          _c("li", { staticClass: "flex_product" }, [
                            _vm._v(_vm._s(items.productData["name"]))
                          ]),
                          _vm.sendmsg
                            ? [
                                _c(
                                  "li",
                                  {
                                    class:
                                      _vm.new_tuisong_val_before[
                                        "international"
                                      ].prices[items["engname"]]["huigou"] <=
                                      items.productData["huigou"]
                                        ? "redfont"
                                        : "greenfont"
                                  },
                                  [
                                    items.productData["name"] == "伦敦金" ||
                                    items.productData["name"] == "伦敦钯" ||
                                    items.productData["name"] == "伦敦铂"
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("changeTwoDecimal_f")(
                                                  items.productData["huigou"]
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      : items.productData["name"] == "伦敦银" ||
                                        items.productData["name"] == "美元指数"
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f(
                                                  "changeTwoDecimal_fthree"
                                                )(items.productData["huigou"])
                                              ) +
                                              " "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                items.productData["huigou"]
                                              ) +
                                              " "
                                          )
                                        ]
                                  ],
                                  2
                                ),
                                _c(
                                  "li",
                                  {
                                    class:
                                      _vm.new_tuisong_val_before[
                                        "international"
                                      ].prices[items["engname"]]["xiaoshou"] <=
                                      items.productData["xiaoshou"]
                                        ? "redfont"
                                        : "greenfont"
                                  },
                                  [
                                    items.productData["name"] == "伦敦金" ||
                                    items.productData["name"] == "伦敦钯" ||
                                    items.productData["name"] == "伦敦铂"
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("changeTwoDecimal_f")(
                                                  items.productData["xiaoshou"]
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      : items.productData["name"] == "伦敦银" ||
                                        items.productData["name"] == "美元指数"
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("changeTwoDecimal_f")(
                                                  items.productData["xiaoshou"]
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                items.productData["xiaoshou"]
                                              ) +
                                              " "
                                          )
                                        ]
                                  ],
                                  2
                                ),
                                _c("li", { staticClass: "lastli" }, [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        _vm.new_tuisong_val_before[
                                          "international"
                                        ].prices[items["engname"]]["high"] <=
                                        items.productData["high"]
                                          ? "redfont"
                                          : "greenfont"
                                    },
                                    [
                                      items.productData["name"] == "伦敦金" ||
                                      items.productData["name"] == "伦敦钯" ||
                                      items.productData["name"] == "伦敦铂"
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("changeTwoDecimal_f")(
                                                    items.productData["high"]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        : items.productData["name"] ==
                                            "伦敦银" ||
                                          items.productData["name"] ==
                                            "美元指数"
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("changeTwoDecimal_f")(
                                                    items.productData["high"]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  items.productData["high"]
                                                ) +
                                                " "
                                            )
                                          ]
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      class:
                                        _vm.new_tuisong_val_before[
                                          "international"
                                        ].prices[items["engname"]]["low"] <=
                                        items.productData["low"]
                                          ? "redfont"
                                          : "greenfont"
                                    },
                                    [
                                      items.productData["name"] == "伦敦金" ||
                                      items.productData["name"] == "伦敦钯" ||
                                      items.productData["name"] == "伦敦铂"
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("changeTwoDecimal_f")(
                                                    items.productData["low"]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        : items.productData["name"] ==
                                            "伦敦银" ||
                                          items.productData["name"] ==
                                            "美元指数"
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("changeTwoDecimal_f")(
                                                    items.productData["low"]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  items.productData["low"]
                                                ) +
                                                " "
                                            )
                                          ]
                                    ],
                                    2
                                  )
                                ])
                              ]
                            : [
                                _c(
                                  "li",
                                  { staticClass: "redfont" },
                                  [
                                    items.productData["name"] == "伦敦金" ||
                                    items.productData["name"] == "伦敦钯" ||
                                    items.productData["name"] == "伦敦铂"
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("changeTwoDecimal_f")(
                                                  items.productData["huigou"]
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      : items.productData["name"] == "伦敦银" ||
                                        items.productData["name"] == "美元指数"
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("changeTwoDecimal_f")(
                                                  items.productData["huigou"]
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                items.productData["huigou"]
                                              ) +
                                              " "
                                          )
                                        ]
                                  ],
                                  2
                                ),
                                _c("li", { staticClass: "redfont" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(items.productData["xiaoshou"]) +
                                      " "
                                  )
                                ]),
                                _c("li", { staticClass: "lastli" }, [
                                  _c("div", [
                                    _vm._v(_vm._s(items.productData["high"]))
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(items.productData["low"]))
                                  ])
                                ])
                              ]
                        ],
                        2
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          ),
          _vm._m(1)
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "flex ulflex topul" }, [
      _c("li", { staticClass: "firstbox" }),
      _c("li", { staticStyle: { flex: "3" } }, [_vm._v("商品")]),
      _c("li", [_vm._v("回购")]),
      _c("li", [_vm._v("销售")]),
      _c("li", [_vm._v("高低")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "foot" }, [
      _c("p", [
        _vm._v(
          "声明:杜绝来路不明的黄金等贵金属、杜绝销售者参与洗钱活动、黄金买卖以实时行情价清账(贷款两清)、定价交易的在七天内结清(须交定金)、定金不可低于金价波动差价。以上行情仅供参考！"
        )
      ]),
      _c("p", { staticClass: "textDecoration" }, [
        _vm._v("公司地址: 北京市东城区天雅珠宝城一楼")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }